.footer_dark, .footer_light{
    display: flex;
    justify-content: space-between;
    color: white;
    padding:60px 10% 50px 10%;
    margin-top: 100px;
}
.footer_dark{
    background: linear-gradient(100deg,rgba(123, 48, 237, 1) 3.56%, rgba(159, 56, 162, 1) 20%, rgba(159, 56, 162, 1) 45%);;
}
.footer_light{
    background: linear-gradient(100deg, rgba(123, 48, 237, 1) 3.56%,rgb(187, 93, 93) 20%, rgba(255, 255, 255, 0.20) 97.67%);
}
.footer_left{
    display: flex;
    /* align-items: justify-content; */
    flex-direction: column;
    gap: 20px;
    margin-top:50px;
}
.left_head{
    font-size: 55px;
}
.email{
    font-size: 25px;
}
.phone{
    font-size: 25px;
}
.social-links-footer{
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-top: 20px;
    gap: 30px;
}

.form{
    display: flex;
    width: 500px;
    flex-wrap: wrap;
    margin-top:30px;

}
.footer_right{
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) 1.56%, rgba(255, 255, 255, 0.20) 97.67%);
    box-shadow: 0px 4px 20px 0px rgba(10, 8, 8, 0.05);
    backdrop-filter: blur(5px);
    padding: 20px;
    /* width: 550px; */
    /* width: fit-content; */
}
.form-name {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    gap:3px;
}

.form-email {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.form-message{
    width:500px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 3px;

}

.input-name, .input-email{
    border-radius: 10px;
    height: 25px;
    width: 200px;
    border: none;
    padding:10px;
    font-size: 20px;
}
.input-message{
    border-radius: 10px;
    /* width: 700px; */
    border: none;
    padding: 10px;
    font-size: 20px;
}
.submit-btn{
    width: 200px;
    height: 50px;
    padding: 12px 24px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 10px;
    background: linear-gradient(90deg, #7B30ED 0%, #AD34FC 100%);
    color: white;
    font-size: large;
    margin-top: 20px;
    border: none;
    margin-left: 300px;
    cursor: pointer;
    transition: background 1s ease;
}
.submit-btn:hover{
    background: linear-gradient(90deg, #AD34FC 0%, #7B30ED 100%);
    /* transition: color 1s ease-in-out; */
    transition: background 1s ease;
}

.right_head{
    color:#55137C;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .footer_dark, .footer_light{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
    }
    .footer_left{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin-top:20px;
    }
    .left_head{
        font-size: 35px;
    }
    .email{
        font-size: 20px;
    }
    .phone{
        font-size: 20px;
    }
    .social-links-footer{
        display: flex;
        justify-content: space-between;
        width: 200px;
        margin: 20px 0 30px -100px;
        align-items: center;
        gap: 30px;
    }
    .form{
        display: flex;
        flex-direction: row;
        width: 510px;
        flex-wrap: wrap;
    }
    .footer_right{
        padding: 10px 0 20px 30px;
        
    }
    .form-name {
        display: flex;
        flex-direction: column;
        margin-right: 75px;
        gap:3px;
    }
    .form-email {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .form-message{
        
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 3px;
    }
    .input-name, .input-email{
        border-radius: 10px;
        height: 25px;
        width: 198px;
        /* width: 302px; */
        border: none;
        /* padding:10px; */
        font-size: 20px;
        margin-right: -40px;
    }
    .input-message{
        border-radius: 10px;
        /* width: 700px; */
        border: none;
        /* padding: 10px; */
        font-size: 20px;
        width: 90%;
        font-family: 'Montserrat',
            sans-serif;
    }
    .submit-btn{
        margin-left: 28%;
    }
    .result{
        margin-left: 28%;
        font-size: large;
        color: #55137C;
    }
}


@media screen and (max-width: 768px) {
    .footer_dark,
        .footer_light {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            margin-top: 50px;
        }
    
        .footer_left {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            margin-top: 20px;
        }
    
        .left_head {
            font-size: 35px;
        }
    
        .email {
            font-size: 20px;
        }
    
        .phone {
            font-size: 20px;
        }
    
        .social-links-footer {
            display: flex;
            justify-content: space-between;
            width: 200px;
            margin: 20px 0 30px -100px;
            align-items: center;
            gap: 30px;
        }
    
        .right_head{
            font-size: 18px;
        }
        .form {
            display: flex;
            flex-direction: row;
            width: 330px;
            flex-wrap: wrap;
        }
    
        .footer_right {
            padding: 5px 0 10px 15px;
    
        }
    
        .form-name {
            display: flex;
            flex-direction: column;
            margin-right: 75px;
            gap: 3px;
        }
    
        .form-email {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
    
        .form-message {
    
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            gap: 3px;
        }
    
        .input-name,
        .input-email {
            border-radius: 10px;
            height: 20px;
            width: 130px;
            /* width: 302px; */
            border: none;
            /* padding:10px; */
            font-size: 15px;
            margin-right: -60px;
        }
    
        .input-message {
            border-radius: 10px;
            /* width: 700px; */
            border: none;
            /* padding: 10px; */
            font-size: 15px;
            width: 90%;
        }
    
        .submit-btn {
            margin-left: 28%;
            width: 120px;
            height: 35px;
            font-size: large;
            margin-top: 20px;
            font-size: medium;
            padding: 0px 24px;
        }
}




