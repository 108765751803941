.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    z-index:1;
    
}
.logo{
    cursor: pointer;
}

.navbar-light{
    
    color: white;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 10px 5%;
    background: url("../../assets/Frrrr.svg");
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
    
}
.navbar-dark {
    color:#820A5B;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 10px 5%;
    background: url("../../assets/navbar-back-light.svg");
    
}
.hamburger{
    width: 50px;
    height: 40px;
    padding-top: 20px;
    cursor: pointer;
    display: none;
}

.nav-links{
    display: flex;
    gap: 5rem;
    font-size: 28px;
    font-weight: 700;
    align-items: center;
    cursor: pointer;
}


.navbar-dark .nav-links .nav-link:hover{
    border-bottom: 2px solid #820A5B;
    padding-bottom: 5px;
}

.navbar-light .nav-links .nav-link:hover {
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
}
.toggle{
    width: 80px;
    height: 39.507px;
    align-items: center;
    padding-top: 25px;
    cursor: pointer;
}

.nav-line-dark{
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgba(248, 244, 244, 0.00) 0%, rgba(232, 230, 230, 0.48) 51.56%, rgba(217, 217, 217, 0.00) 100%);
}

.nav-line-light {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgba(189, 87, 174, 0.00) 0%, #C343B1 51.56%, rgba(195, 67, 177, 0.00) 100%);
}

.sidebar{
    padding-left: 5%;
    padding-top: 5%;
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    width: 220px;
    height: 400px;
    background-color: #c53b97;
    align-content: center;
    gap: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    border-radius: 0px 0px 20px 0px;
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
}
 
.sidebar-active{
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
    padding-left: 5%;
    padding-top: 4.5%;
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    width: 220px;
    height: 400px;
    background-color: #c53b97;
    align-content: center;
    gap: 30px;
    color: white;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    border-radius: 0px 0px 20px 0px;
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
}
.sidebar-active-dark {
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
    padding-left: 5%;
    padding-top: 4.5%;
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    width: 220px;
    height: 400px;
    background-color: #c53b97;
    align-content: center;
    gap: 30px;
    color: black;
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    border-radius: 0px 0px 20px 0px;
    background: linear-gradient(90deg, #eecbf0 3.07%, #eeccef 6.85%, #e8b2e9 35.59%, #f1cfe6 99.28%);
}


.sidebar-links{
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: justify;
    cursor: pointer;
}

.sidebar-header{
    display: flex;
    gap:70px;
    cursor: pointer;
}
.cross:hover {
    font-size: larger;
}
@media(max-width:768px) {
        .nav-links {
            display: none;
        }
    
        .logo {
            height: 50px;
            padding-top: 10px;
            padding-left:20px;
        }
    
        .toggle {
            height: 20px;
            
        }
    
        .hamburger {
            display: block;
            padding-top: 15px;
        }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    
    .nav-links{
        display:none;
    }

    .logo{
        height: 70px;
        padding-left: 20px;
    }
    .toggle{
        height: 25px;
    }
    .hamburger{
        display: block;
    }
    
}



