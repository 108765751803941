.projects{
    min-height: 1000px;
    
}

.projects_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    min-height: 100vh;
    
    column-gap: 0px;
    row-gap: 30px;
}
.projects_heading {
    margin-top: 100px;
    margin-bottom: 50px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .projects_container{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
        min-height: 150vh;
        
        column-gap: 0px;
        row-gap: 30px;
    }
    .projects{
        margin-bottom: 50px;
    }
    .projects_heading{
        margin-top: 100px;
        margin-bottom: 50px;
    }
}