
.experience-container{
    display:flex;
    flex-direction:column;
    align-items: center ;
}

.two{
    margin-top: 100px;
    display:flex;
    flex-direction:row;
    gap: 5rem;
    align-items:center;
    margin-bottom:1rem;
}
.one-tablet{
    display: none;
}
.experience-heading {
    margin-top: 100px;
    margin-bottom: 50px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .two{
        margin-top: 100px;
        display:flex;
        flex-direction:column;
        gap: 5rem;
        align-items:center;
        margin-bottom:1rem;
    }

    .one-tablet{
        display: block;
    }
    .one{
        display: none;
    }
    .experience-heading{
        margin-top: 100px;
        margin-bottom: 50px;
    }
}
