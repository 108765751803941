.achievements, .achievements-light{
    background: rgb(168, 95, 203, 0.8);
    
    border-radius: 30px;
    border: 0.5px solid #FFF;
    width: 400px;
    height: 500px;
    color: #FFF;
}
.achievements-light{
    background: rgb(168, 95, 203);
}
.achievements-logo{
    width: 160px;
    margin: 10% 0 0 31%;
}
.achievements-heading{
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}

.achievements-box{
    margin-top: 30px;
    font-size:18px;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
    
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .achievements,.achievements-light{
        width:300px;
        height: 400px;
    }
    .achievements-heading{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    }
    .achievements-logo{
    width: 100px;
    margin: 10% 0 0 33%;
    }
    .achievements-box{

    font-size:15px;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
    
}
}

@media screen and (max-width: 768px) {
    .achievements,
        .achievements-light {
            width: 250px;
            height: 300px;
        }
    .achievements-heading {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
    .achievements-logo{
    width: 100px;
    margin: 10% 0 0 30%;
    }
     .achievements-box{

    font-size:12px;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
    margin-top:20px;
}
}