.skills{
    height: 900px;
    margin: 5% 10% 0 0;
    align-items: center;
    
}
.skills-container{
    margin: 5% 12%;
    position: relative;
}

.trolly-man{
    position: absolute;
    top: -80px;
    left: 0;
    object-fit: cover;
    width: 1300px;
    
}
.skills-set{
    left: 100px;
    top: 20px;
    position: absolute;
    
}
.skill-set-icon{
    width: 800px;
    
}

.nav-line-dark-skills {
    width:100%;
    height: 2px;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgba(248, 244, 244, 0.00) 0%, rgba(232, 230, 230, 0.48) 51.56%, rgba(217, 217, 217, 0.00) 100%);
    position: absolute; 
    top: 632px;
    left: 170px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    /* .trolly-man{
        /* width: 100%; 
        height: 60%;
        margin-left: 15%;
    }
    .skill-set-icon{
        width: 55%;
        margin-left: 20%;
    }
    .skills-container{
    /* margin-left: 500px; 
    position: relative;
    height: 700px;
    }
    .nav-line-dark-skills{
        top:400px;
    } */
    .skills{
        display: none;
    }
}