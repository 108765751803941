.mobile-projects {
    /* padding-left: 5%;
    padding-top: 5%; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #c53b97;
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
}

.mobile-projects-dark {
    /* padding-left: 5%;
    padding-top: 5%; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #c53b97;
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
}

.mobile-projects-active {
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    height: calc(100% - 0px);
    overflow-y: auto;
    width: 100%;
    z-index: 99999999;
    background: linear-gradient(90deg, #491696 3.07%, #4A1699 6.85%, #54137E 35.59%, #610844 99.28%);
}

.mobile-projects-active-dark {
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    height: calc(100% - 0px);
    overflow-y: auto;
    width: 100%;
    z-index: 99999999;
    background-color: #E4D6EE;
    background: linear-gradient(90deg, #eecbf0 3.07%, #eeccef 6.85%, #e8b2e9 35.59%, #f1cfe6 99.28%);
}

.mobile-projects-head {
    margin-top: 10px;
    margin-left: -75px;
    /* align-items: center; */
    display: flex;
    gap: 70px;
}


.cross-mobile {
    margin-left: 8%;
    margin-top: 50px;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.cross-mobile-dark {
    margin-left: 8%;
    margin-top: 50px;
    font-size: 30px;
    font-weight: 800;
    color: #c53b97;
    cursor: pointer;
}

.mobile-projects-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin-top: 30px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .mobile-projects-head {
        margin-left: 20px;
        /* align-items: center; */
        display: flex;
        gap: 70px;
    }
}