.singleProjectLight{
    width: 550px;
    height: 450px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(#AE46FF, #D22FBA);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;
    padding: 30px 30px 8px 40px;
    
}
.singleProjectDark{
    width: 550px;
    height: 450px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) 1.56%, rgba(255, 255, 255, 0.20) 97.67%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;
    padding: 30px 30px 8px 40px;
}
.singleProject_body_description
{
    font-size: 20px;
    width:90%;
}

.singleProject_head_title{
    font-size: 34px;
    font-style: normal;
    font-weight: 600;

}
.singleProject_body_technologies
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
    
    
}
.skill_image_project{
    background-color: #521587;
    border-radius: 10px;
    text-align: center;
    padding:10px;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #BC41AE;
}

.singleProject_body_links
{
    position: fixed;
    bottom: 10px;
    right: 20px;
    
}

.singleProject_body_links  a{
    cursor: pointer;
    margin-left: 15px;
}

@media screen and (max-width: 768px){
    .singleProjectLight {
            width: 300px;
            height: fit-content;
            border-radius: 20px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: linear-gradient(#AE46FF, #D22FBA);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(5px);
            color: white;
            padding: 30px 30px 8px 40px;
    
        }
    
        .singleProjectDark {
            width: 300px;
            height: fit-content;
            border-radius: 20px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) 1.56%, rgba(255, 255, 255, 0.20) 97.67%);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(5px);
            color: white;
            padding: 15px 30px 8px 40px;
        }
    
        .singleProject_body_description {
            font-size: 18px;
            width: 90%;
        }
    
        .singleProject_head_title {
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
    
        }
    
        .singleProject_body_technologies {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 30px;
    
        }
    
        .skill_image_project {
            background-color: #521587;
            border-radius: 10px;
            text-align: center;
            padding: 10px;
            align-items: center;
            height: 30px;
            border-radius: 10px;
        }
    
        .singleProject_body_links {
            position: relative;
            float:right;
            margin-right:-20px;
            /* right: 40px; */
        }
    
       
}