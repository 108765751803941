.education, .education-light{
    color:white;
    background: rgb(168, 95, 203,0.8);
    width: 400px;
    height: 500px;
    border-radius: 30px;
    border: 0.5px solid #FFF;
}
.education-light{
    background: rgb(168, 95, 203);
}
.education-logo{
    width: 160px;
    margin: 10% 0 0 31%;
    
}
.education-list{
    
    list-style: circle url("../../assets/dot.svg");
}
.education-text{
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}
.education-list-item{
    margin-top: 20px;
}
.education-list-item-title{
    font-size: 20px;
}
.education-list-item-text{
    margin-top: -15px;
    font-size: 16px;
    font-weight: 500;
    padding-right: 30px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .education, .education-light{
    width: 300px;
    height: 400px;
    }
    .education-logo{
    width: 100px;
    margin: 10% 0 0 33%;   
    }
    .education-text{
    
    font-size: 20px;
    }

    .education-list-item-title{
    font-size: 15px;
}
.education-list-item-text {
    margin-top: -5px;
    font-size: 14px;
    font-weight: 500;
}

}

@media screen and (max-width: 768px) {
.education, .education-light{
    width: 250px;
    height: 300px;
    }
    .education-logo{
    width: 100px;
    margin: 10% 0 0 30%;   
    }
    .education-text{
    font-size: 16px;
    }
    .education-list-item{
        margin-top:10px;
    }

    .education-list-item-title{
    font-size: 12px;
}
.education-list-item-text {
    margin-top: -10px;
    font-size: 10px;
    font-weight: 500;
}
}