.jobs,.jobs-light {
    background: rgb(168, 95, 203, 0.8);
    border-radius: 30px;
    border: 0.5px solid #FFF;
    width: 400px;
    height: 500px;
    color: #FFF;
    
}
.jobs-light {
    background: rgb(168, 95, 203);
}

.jobs-logo {
    width: 160px;
    margin: 10% 0 0 31%;
}


.jobs-heading {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    
}

.year {
    font-size: 30px;
    text-align: justify;
    margin-top: 30px;
}

.jobs-text{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {

.jobs,.jobs-light {
        width: 300px;
        height: 400px;
    }

    .jobs-logo {
    width: 100px;
    margin: 10% 0 0 33%;
}
.jobs-heading {
    font-size: 20px;    
}
.year {
    font-size: 20px;
}
.jobs-text{
    font-size: 15px;
}
}

@media screen and (max-width: 768px) {
    .jobs,
        .jobs-light {
            width: 250px;
            height: 300px;
        }
    
        .jobs-logo {
            width: 100px;
            margin: 10% 0 0 30%;
        }
    
        .jobs-heading {
            font-size: 16px;
        }
    
        .year {
            font-size: 16px;
        }
    
        .jobs-text {
            font-size: 16px;
        }
}