.largeExperienceDark{
    width: 1200px;

    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) 1.56%, rgba(255, 255, 255, 0.20) 97.67%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;       
}

.largeExperienceLight{
    width: 1200px;

    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(#AE46FF, #D22FBA);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;
}

.largeExperience_head{
    justify-content: space-around;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 3%;
}

.largeExperience_head_right{
    display: flex;
    justify-content: space-between;
    gap:30px;
    align-items: center;
}
.largeExperience_head_left_title{
    color: #521587;
}

.largeExperience_head_right_technology{
    background-color: #521587;
    height: 50px;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #BC41AE;
    /* opacity: 0.7; */
}
.skill_image{
    align-items: center;
    height: 35px;
    margin: 8px;
}

.largeExperience_body{
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px;
width: 75%;
margin: 3% 8%;
}
.largeExperience_body_description_container{
    display: flex;
    gap:10px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    
}