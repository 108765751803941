.navbar-light .navbar-active{
    border-bottom: 2px solid #fff;
    padding-bottom: 5px;
}
.navbar-dark .navbar-active {
    border-bottom: 2px solid #820A5B;
    padding-bottom: 5px;
}
.dark-bg{
    background-image: url('./assets/background-attached.png');
    background-size: cover;
    height: fit-content;
    width: fit-content;
    min-width: 100vw;
    min-height: 200vh;
}
.light-bg{
    background-image: url('./assets/total-bg-light.svg');
    background-size: cover;
    height: fit-content;
    width: fit-content;
    min-width: 100vw;
    min-height: 200vh;
}
.blur{
    position: relative;
}
.blur::before{
    content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
}








