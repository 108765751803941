.smallExperienceLight{
    
    width: 600px;
    min-height: 630px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(#AE46FF, #D22FBA);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;
    padding: 0px 40px 20px 40px;
}

.smallExperienceDark{
    width: 600px;
    min-height: 630px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: linear-gradient(132deg, rgba(255, 255, 255, 0.60) 1.56%, rgba(255, 255, 255, 0.20) 97.67%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    color: white;
    padding: 0px 40px 20px 40px;
}

.smallExperience_head {
    justify-content: space-around;
    color: white;
    display: flex;
    margin-top: 3%;
    flex-direction: column;
}

.smallExperience_head_right {
    display: flex;
    gap: 30px;
    align-items: center;
}

.smallExperience_head_left_title {
    color: #521587;
}

.smallExperience_head_right_technology {
    background-color: #521587;
    height: 50px;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #BC41AE;
    /* opacity: 0.7; */
}

.skill_image {
    align-items: center;
    height: 35px;
    /* border-radius: 10px; */
}

.smallExperience_body {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
}

.smallExperience_body_description_container {
    display: flex;
    gap: 10px;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
.smallExperienceLight {

        width: 400px;
        /* min-height: 630px; */
        padding: 0px 20px 5px 20px;
    }

    .smallExperienceDark {
        width: 400px;
        /* min-height: 630px; */
        padding: 0px 20px 5px 20px;
    }

    .smallExperience_head_left h2 {
        font-size: 25px;
        font-weight: 800;

    }

    .smallExperience_head_right {
        gap: 15px;
    }

    .smallExperience_head_left_company {
        font-size: 19px;
        line-height: 25px;
    }

    .smallExperience_head_right_technology {
        height: 45px;
        padding: 0px;
        border-radius: 10px;
        text-align: center;
        /* opacity: 0.7; */
        border: 1px solid #BC41AE;
    }

    .skill_image {
        height: 30px;
    }

    .smallExperience_body {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
    }

    .smallExperience_body_description_container {
        display: flex;
        gap: 10px;
    }
}

@media screen and (max-width: 768px){
    .smallExperienceLight {
    
            width: 350px;
            /* min-height: 630px; */
            padding: 0px 20px 5px 20px;
        }
    
        .smallExperienceDark {
            width: 350px;
            /* min-height: 630px; */
            padding: 0px 20px 5px 20px;
        }
        .smallExperience_head_left h2{
            font-size: 25px;
            font-weight: 800;

        }
    
        .smallExperience_head_right {
            gap: 13px;
        }
        .smallExperience_head_left_company{
            font-size:15px;
            line-height: 25px;
        }
    
        .smallExperience_head_right_technology {
            height: 45px;
            padding: 0px;
            border-radius: 10px;
            text-align: center;
            /* opacity: 0.7; */
        }
    
        .skill_image {
            height: 30px;
        }
    
        .smallExperience_body {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 20px;
        }
    
        .smallExperience_body_description_container {
            display: flex;
            gap: 10px;
        }
    
}