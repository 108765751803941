.main{
    display: flex;
    margin: 0px 5%;;
    justify-content: space-between;
    padding-top: 5%;
}

.main-right{
    width: 700px;
}
.main-left-light{
    padding-top: 120px;
    height: 540px;
    display: flex;
    flex-direction: column;
    color:#820A5B;
    gap: 25px;
}
.main-left-dark{
    padding-top: 120px;
    height: 540px;
    display: flex;
    flex-direction: column;
    color:white;
    gap: 25px;
}
.span-hello{
    font-size: 50px;
    font-weight: 400;
}
.span-name{
    font-size: 55px;
    font-weight: 700;
}
.span-description{
    width: 650px;
    font-size: 20px;
    line-height: 55px;
}
.social-links-dark, .social-links-light{
    display: flex;
    gap: 40px;
}
.resume-btn{
    width: 200px;
    height: 50px;
    padding: 12px 24px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 10px;
    background: linear-gradient(90deg, #7B30ED 0%, #AD34FC 100%);
    color: white;
    font-size: large;
    margin-top: 20px;
    margin-bottom: 30px;
    border: none;
    cursor: pointer;
    transition: background 1s ease;
}

.resume-btn:hover {
    background: linear-gradient(90deg, #AD34FC 0%, #7B30ED 100%);
    /* transition: color 1s ease-in-out; */
    transition: background 1s ease;
}



@media screen and (min-width: 769px) and (max-width: 1023px) {

    .main {
            flex-direction: column-reverse;
            margin: 0px 5%;
            padding-top: 5%;
        }
    
        .main-right {
            width: 60%;
            align-items: center;
            margin: 25px 20px 0px 20%;
        }
    
        .main-left-light,
        .main-left-dark {
            padding-top: 30px;
            align-items: center;
            height:330px;
        }
    
        .span-hello {
            font-size: 25px;
        }
    
        .span-name {
            font-size: 29px;
        }
    
        .span-description {
            font-size: 17px;
            font-weight: 400;
            line-height: 36px;
            justify-content: center;
        }
}
@media screen and (max-width: 768px) {
    .main{
        flex-direction: column-reverse;
        padding-top: 20%;
    }
    .main-right{
        width: 90%;
        margin: 25px 20px 0px 20px;
    }
    .main-left-light, .main-left-dark{
        padding-top: 30px;
        align-items: center;
        height: 400px;
    }
    .span-hello{
        font-size: 25px;
    }
    .span-name{
        font-size: 29px;
    }
    .span-description{
        width: 400px;
        font-size: 17px;
        font-weight: 400;
        line-height: 36px;
        justify-content: center;
    }
}

